<template>
  <div
    ref="container"
    class="c-facet__search-modal u-flex"
  >
    <div class="c-facet__search-modal-container u-flex u-flex-direction-column u-flex-1">
      <div class="u-flex u-width-100">
        <CloseButton
          class="u-ml--a"
          :size="16"
          icon-color="black"
          icon-hover-color="black"
          button-size="small"
          @click="closeModal"
        />
      </div>
      <div
        class="c-facet__search-modal-content u-mt--s u-mb--l u-flex u-flex-direction-column u-flex-shrink-0 u-flex-1"
      >
        <slot />
      </div>
    </div>
    <div
      class="c-facet__search-modal-overlay"
      @click="closeModal"
    />
  </div>
</template>

<script>
import { disablePageScroll, enablePageScroll } from 'scroll-lock'
import store from '@/CVI/Facet/store'

import { showHeader, hideHeader } from '@/CVI/WebCore/components/Header'
import CloseButton from '@/CVI/WebCore/components/CloseButton.vue'

export default {
  components: {
    CloseButton
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      store,
      isVisible: false
    }
  },
  mounted() {
    disablePageScroll([this.$refs.container.querySelector('.autocomplete-result-list'), this.$refs.container.querySelector('.c-facet__search-bar-input-mobile-tag-scroller')])
    hideHeader()
  },
  destroyed() {
    showHeader()
    enablePageScroll()
  },
  methods: {
    closeModal() {
      this.$emit('hideModal')
    }
  }
}
</script>
